<template>
  <RenataStyleProvider>
    <ErrorBoundary :error="routerError">
      <UINotifications />
      <router-view />
      <ProgressBar />
    </ErrorBoundary>
  </RenataStyleProvider>
</template>

<script setup lang="ts">
import { RouterView } from "vue-router";
import useAuthStateRedirect from "@/composables/useAuthStateRedirect";
import ProgressBar from "@/components/ProgressBar/ProgressBar.vue";
import RenataStyleProvider from "@/uikit/renata/components/RenataStyleProvider.vue";
import UINotifications from "@/uikit/renata/components/UINotification/UINotifications.vue";
import { useSyncCSRFWithRN } from "@/composables/useSyncCSRFWithRN";
import { useSyncNewUXFlagWithRN } from "@/composables/useSyncNewUXFlagWithRN";
import { useUnreadCountersStore } from "@/stores/unreadCountersStore";
import { useAuthSessionStore } from "@/stores/authSessionStore";
import { computed, inject, watchEffect } from "vue";
import ErrorBoundary from "@/components/error/ErrorBoundary.vue";
import { routerShowErrorPage } from "@/router";
import { useWatchPostHogConsent } from "@/posthog/composables";
import "@/utils/mobileRedirect";

useAuthStateRedirect();
useSyncCSRFWithRN();
useSyncNewUXFlagWithRN();
const authSessionStore = useAuthSessionStore();
const unreadCountersStore = useUnreadCountersStore();

useWatchPostHogConsent();

/**
 * For debugging purposes only.
 * This function is exposed to the window object and allows to change the CSRF token.
 *
 * We use it to test the CSRF related features in the e2e tests.
 */
(window as any)._changeCSRFToken = (newToken: string | undefined) => {
  if (typeof newToken !== "string" && newToken !== undefined) {
    throw new Error("CSRF token must be a string or undefined");
  }

  (authSessionStore.meta as any).csrf_token = newToken;
};

const updateCountersFunction = computed(() => {
  const isAuthenticated = authSessionStore.initialized ? !!authSessionStore.session : false;
  const hasToGiveDataSharingConsent = authSessionStore.hasToGiveDataSharingConsent;
  const hasToAddEligibilityDetails = authSessionStore.hasToAddEligibilityDetails;

  if (!isAuthenticated || hasToGiveDataSharingConsent || hasToAddEligibilityDetails) {
    return;
  }

  const notificationsEnabled = authSessionStore.session?.attributes.notifications_enabled;

  return () => {
    unreadCountersStore.fetchCareNavigatorUnreadMessagesCount().catch((e) => console.error(e));

    if (notificationsEnabled) {
      unreadCountersStore.fetchNotificationsUnreadCount().catch((e) => console.error(e));
      unreadCountersStore.fetchPaymentsUnreadCount().catch((e) => console.error(e));
    }
  };
});

const routerError = inject(routerShowErrorPage);

watchEffect((onCleanup) => {
  const updateCounters = updateCountersFunction.value;

  if (updateCounters) {
    updateCounters();

    const interval = setInterval(updateCounters, 30000);

    onCleanup(() => clearInterval(interval));
  }
});
</script>
