import { useTranslator } from "@/composables/useTranslator";
import { translateMonth } from "@/i18n";

/**
 * `Date` object to an `ISO 8601` date string.
 *
 *  Example: 2024-02-09
 *
 *  The `toISOString()` method of `Date` instances returns a string representing this date in the date time string format,
 *  a simplified format based on ISO 8601, which is always 24 or 27 characters long
 *  (YYYY-MM-DDTHH:mm:ss.sssZ or ±YYYYYY-MM-DDTHH:mm:ss.sssZ, respectively).
 *  The timezone is always UTC, as denoted by the suffix Z.
 *
 *  So we can use the `toISOString()` method to get the date in the format we want
 *    and then use the `split()` method to get the date part.
 */
export function formatToISO8601Date(date: Date): string {
  return date.toISOString().split("T")[0];
}

/**
 * Parses an `ISO 8601` date string to a `Date` object.
 *
 * Example: 2024-02-09
 *
 *
 *According to the MDN Web Docs, the `Date` constructor can parse an `ISO 8601` date string:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
 *
 * MDN says:
 *  Date-only form: YYYY, YYYY-MM, YYYY-MM-DD
 *  For example, "2011-10-10" (date-only form), "2011-10-10T14:48:00" (date-time form),
 *    or "2011-10-10T14:48:00.000+09:00" (date-time form with milliseconds and time zone)
 *    are all valid date time strings.
 *
 *  When the time zone offset is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time.
 *  This is due to a historical spec error that was not consistent with ISO 8601 but could not be changed due to web compatibility.
 *
 */
export function parseIso8601Date(date: string): Date {
  if (!isIso8601DateString(date)) throw new Error("Invalid ISO 8601 date string");
  return new Date(date);
}

/**
 * Checks if the given date string is a valid `ISO 8601` date string.
 *
 * Example: 2024-02-09 is a valid `ISO 8601` date string.
 * While 2024-02-09T23:00:00.000Z is not a valid `ISO 8601` date string.
 * And 2024-15-09 is not a valid `ISO 8601` date string as well.
 */
export function isIso8601DateString(date: string): boolean {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const isValidFormat = dateRegex.test(date);

  if (!isValidFormat) return false;

  return !isNaN(Date.parse(date));
}

/**
 * Returns the start of the day for the given date.
 *
 * Similar to `startOfDay` from `date-fns` but without the need to import the library.
 */
export function startOfDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

// Mon, Nov 28, 2022, 5:30 AM
export function formatDateTimeShort(date: Date, timeZone?: string): string {
  const translator = useTranslator();
  const formatter = new Intl.DateTimeFormat(translator.currentLocale, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "short",
    timeZone,
    weekday: "short",
    year: "numeric",
  });

  return formatter.format(date);
}

// May 5, 2022
export function formatDate(date: Date, timeZone?: string): string {
  const translator = useTranslator();
  const formatter = new Intl.DateTimeFormat(translator.currentLocale, {
    day: "numeric",
    month: "short",
    timeZone,
    year: "numeric",
  });

  return formatter.format(date);
}

export function formatDOB(date: Date): string {
  const translator = useTranslator();
  const formatter = new Intl.DateTimeFormat(translator.currentLocale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formatter.format(date);
}

// validate DOB from dob select input
export function isValidDOB(year: number, month: number, day: number): boolean {
  if (year < 1000 || year > 3000 || month < 1 || month > 12) {
    return false;
  }

  const date = new Date(Date.UTC(year, month - 1, day));

  return (
    date.getUTCFullYear() === year && date.getUTCMonth() === month - 1 && date.getUTCDate() === day
  );
}

/**
 * Formats a date to a numeric LOCALE format.
 * Example: 03-31-2021 or 31-03-2021 or 31/03/2021 or 31. 03. 2021
 */
export function formatDateNumeric(date: Date): string {
  const translator = useTranslator();

  return new Intl.DateTimeFormat(translator.currentLocale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);
}

export function formatDateTime(date: Date, timeZone?: string): string {
  const translator = useTranslator();
  const formatter = new Intl.DateTimeFormat(translator.currentLocale, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "short",
    timeZone,
  });

  return formatter.format(date);
}

export function localizeMonth(month: number): string {
  const translator = useTranslator();

  const format = new Intl.DateTimeFormat(translator.currentLocale, {
    month: "long",
  });
  const date = new Date(0, month, 1, 0, 0, 0, 0);
  return format.format(date);
}

export const getLocalizedMonths = () => [
  localizeMonth(0),
  localizeMonth(1),
  localizeMonth(2),
  localizeMonth(3),
  localizeMonth(4),
  localizeMonth(5),
  localizeMonth(6),
  localizeMonth(7),
  localizeMonth(8),
  localizeMonth(9),
  localizeMonth(10),
  localizeMonth(11),
];

// Mon, Nov 28, 2022, 5:30 AM PST
export function formatWeekDateTimeZone(date: Date, timeZone?: string): string {
  const translator = useTranslator();
  const formatter = new Intl.DateTimeFormat(translator?.currentLocale, {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "short",
    timeZone,
    timeZoneName: "short",
    weekday: "short",
    year: "numeric",
  });

  return formatter.format(date);
}
