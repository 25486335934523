<template>
  <component
    v-bind="props"
    :is="icon"
    role="img"
    class="ui-icon"
    :class="{
      inverse: name === 'arrow-right',
    }"
  />
</template>

<script lang="ts">
import IconBank from "@/uikit/renata/components/UIIcon/templates/IconBank.vue";
import IconCoin from "@/uikit/renata/components/UIIcon/templates/IconCoin.vue";
import Loader from "@/uikit/renata/components/UIIcon/templates/IconLoader.vue";
import Cog from "@/uikit/renata/components/UIIcon/templates/IconCog.vue";
import CogFilled from "@/uikit/renata/components/UIIcon/templates/IconCogFilled.vue";
import Bars from "@/uikit/renata/components/UIIcon/templates/IconBars.vue";
import Chevron from "@/uikit/renata/components/UIIcon/templates/IconChevron.vue";
import Eye from "@/uikit/renata/components/UIIcon/templates/IconEye.vue";
import EyeStrikethrough from "@/uikit/renata/components/UIIcon/templates/IconEyeStrikethrough.vue";
import PlusRounded from "@/uikit/renata/components/UIIcon/templates/IconPlusRounded.vue";
import Warning from "@/uikit/renata/components/UIIcon/templates/IconWarning.vue";
import WarningRounded from "@/uikit/renata/components/UIIcon/templates/IconWarningRounded.vue";
import WarningRoundedFilled from "@/uikit/renata/components/UIIcon/templates/IconWarningRoundedFilled.vue";
import Calendar from "@/uikit/renata/components/UIIcon/templates/IconCalendar.vue";
import CalendarSelected from "@/uikit/renata/components/UIIcon/templates/IconCalendarSelected.vue";
import Envelope from "@/uikit/renata/components/UIIcon/templates/IconEnvelope.vue";
import EnvelopeChecked from "@/uikit/renata/components/UIIcon/templates/IconEnvelopeChecked.vue";
import EnvelopeNotification from "@/uikit/renata/components/UIIcon/templates/IconEnvelopeNotification.vue";
import EnvelopeChat from "@/uikit/renata/components/UIIcon/templates/IconEnvelopeChat.vue";
import Check from "@/uikit/renata/components/UIIcon/templates/IconCheck.vue";
import ProfilePhone from "@/uikit/renata/components/UIIcon/templates/IconProfilePhone.vue";
import PhoneSmall from "@/uikit/renata/components/UIIcon/templates/IconPhoneSmall.vue";
import HouseFilled from "@/uikit/renata/components/UIIcon/templates/IconHouseFilled.vue";
import House from "@/uikit/renata/components/UIIcon/templates/IconHouse.vue";
import Comment from "@/uikit/renata/components/UIIcon/templates/IconComment.vue";
import Globe from "@/uikit/renata/components/UIIcon/templates/IconGlobe.vue";
import Arrow from "@/uikit/renata/components/UIIcon/templates/IconArrow.vue";
import Stork from "@/uikit/renata/components/UIIcon/templates/IconStork.vue";
import RadioUnchecked from "@/uikit/renata/components/UIIcon/templates/IconRadioUnchecked.vue";
import RadioTickChecked from "@/uikit/renata/components/UIIcon/templates/IconRadioTickChecked.vue";
import RadioTickCheckedFilled from "@/uikit/renata/components/UIIcon/templates/IconRadioTickCheckedFilled.vue";
import RadioDotChecked from "@/uikit/renata/components/UIIcon/templates/IconRadioDotChecked.vue";
import Payment from "@/uikit/renata/components/UIIcon/templates/IconPayment.vue";
import Cookies from "@/uikit/renata/components/UIIcon/templates/IconCookies.vue";
import Star from "@/uikit/renata/components/UIIcon/templates/IconStar.vue";
import Navigation from "@/uikit/renata/components/UIIcon/templates/IconNavigation.vue";
import Cross from "@/uikit/renata/components/UIIcon/templates/IconCross.vue";
import SummarizeFilled from "@/uikit/renata/components/UIIcon/templates/IconSummarizeFilled.vue";
import Summarize from "@/uikit/renata/components/UIIcon/templates/IconSummarize.vue";
import DoulaFilled from "@/uikit/renata/components/UIIcon/templates/IconDoulaFilled.vue";
import Doula from "@/uikit/renata/components/UIIcon/templates/IconDoula.vue";
import GiftFilled from "@/uikit/renata/components/UIIcon/templates/IconGiftFilled.vue";
import Gift from "@/uikit/renata/components/UIIcon/templates/IconGift.vue";
import InfoRounded from "@/uikit/renata/components/UIIcon/templates/IconInfoRounded.vue";
import Info from "@/uikit/renata/components/UIIcon/templates/IconInfo.vue";
import Block from "@/uikit/renata/components/UIIcon/templates/IconBlock.vue";
import AssistedPregnancy from "@/uikit/renata/components/UIIcon/templates/IconAssistedPregnancy.vue";
import EggFreezing from "@/uikit/renata/components/UIIcon/templates/IconEggFreezing.vue";
import Adoption from "@/uikit/renata/components/UIIcon/templates/IconAdoption.vue";
import Surrogacy from "@/uikit/renata/components/UIIcon/templates/IconSurrogacy.vue";
import SettingsAppSettings from "@/uikit/renata/components/UIIcon/templates/IconSettingsAppSettings.vue";
import SettingsBasics from "@/uikit/renata/components/UIIcon/templates/IconSettingsBasics.vue";
import SettingsHealthGoal from "@/uikit/renata/components/UIIcon/templates/IconSettingsHealthGoal.vue";
import SettingsHealthPartner from "@/uikit/renata/components/UIIcon/templates/IconSettingsHealthPartner.vue";
import SettingsHealthProfile from "@/uikit/renata/components/UIIcon/templates/IconSettingsHealthProfile.vue";
import SettingsLogout from "@/uikit/renata/components/UIIcon/templates/IconSettingsLogout.vue";
import IconPackage from "@/uikit/renata/components/UIIcon/templates/IconPackage.vue";
import Chat from "@/uikit/renata/components/UIIcon/templates/IconChat.vue";
import Dots from "@/uikit/renata/components/UIIcon/templates/IconDots.vue";
import Fertility from "@/uikit/renata/components/UIIcon/templates/IconFertility.vue";
import SelfCare from "@/uikit/renata/components/UIIcon/templates/IconSelfCare.vue";
import ChildCare from "@/uikit/renata/components/UIIcon/templates/IconChildCare.vue";
import Suitcase from "@/uikit/renata/components/UIIcon/templates/IconSuitcase.vue";
import Rewind from "@/uikit/renata/components/UIIcon/templates/IconRewind.vue";
import Male from "@/uikit/renata/components/UIIcon/templates/IconMale.vue";
import Syringe from "@/uikit/renata/components/UIIcon/templates/IconSyringe.vue";
import Cabin from "@/uikit/renata/components/UIIcon/templates/IconCabin.vue";
import TestTube from "@/uikit/renata/components/UIIcon/templates/IconTestTube.vue";
import Breastfeeding from "@/uikit/renata/components/UIIcon/templates/IconBreastfeeding.vue";
import NursingRooms from "@/uikit/renata/components/UIIcon/templates/IconNursingRooms.vue";
import BabyBottle from "@/uikit/renata/components/UIIcon/templates/IconBabyBottle.vue";
import BackupCare from "@/uikit/renata/components/UIIcon/templates/IconBackupCare.vue";
import PlayPause from "@/uikit/renata/components/UIIcon/templates/IconPlayPause.vue";
import GenderAffirming from "@/uikit/renata/components/UIIcon/templates/IconGenderAffirming.vue";
import Home from "@/uikit/renata/components/UIIcon/templates/IconHome.vue";
import People from "@/uikit/renata/components/UIIcon/templates/IconPeople.vue";
import Insight from "@/uikit/renata/components/UIIcon/templates/IconInsight.vue";
import Bell from "@/uikit/renata/components/UIIcon/templates/IconBell.vue";
import IconRedeem from "@/uikit/renata/components/UIIcon/templates/IconRedeem.vue";
import IconRemove from "@/uikit/renata/components/UIIcon/templates/IconRemove.vue";
import IconLock from "@/uikit/renata/components/UIIcon/templates/IconLock.vue";
import IconPlus from "@/uikit/renata/components/UIIcon/templates/IconPlus.vue";
import Upload from "@/uikit/renata/components/UIIcon/templates/IconUpload.vue";
import Dollar from "@/uikit/renata/components/UIIcon/templates/IconDollar.vue";
import IconObGyn from "@/uikit/renata/components/UIIcon/templates/IconObGyn.vue";
import IconHomeHearth from "@/uikit/renata/components/UIIcon/templates/IconHomeHearth.vue";
import DiabetesManagement from "@/uikit/renata/components/UIIcon/templates/IconDiabetesManagement.vue";
import HeartHealth from "@/uikit/renata/components/UIIcon/templates/IconHeartHealth.vue";
import EndometriosisCare from "@/uikit/renata/components/UIIcon/templates/IconEndometriosisCare.vue";
import PelvicHealth from "@/uikit/renata/components/UIIcon/templates/IconPelvicHealth.vue";
import ChronicConditions from "@/uikit/renata/components/UIIcon/templates/IconChronicConditions.vue";
import IconCancel from "@/uikit/renata/components/UIIcon/templates/IconCancel.vue";
import TrianglePointer from "@/uikit/renata/components/UIIcon/templates/IconTrianglePointer.vue";

export const iconsDict = {
  bank: IconBank,
  coin: IconCoin,
  loader: Loader,
  cog: Cog,
  "cog-filled": CogFilled,
  bars: Bars,
  chevron: Chevron,
  eye: Eye,
  "eye-strikethrough": EyeStrikethrough,
  "plus-rounded": PlusRounded,
  warning: Warning,
  "warning-rounded": WarningRounded,
  "warning-rounded-filled": WarningRoundedFilled,
  calendar: Calendar,
  "calendar-selected": CalendarSelected,
  envelope: Envelope,
  "envelope-checked": EnvelopeChecked,
  "envelope-notification": EnvelopeNotification,
  "envelope-chat": EnvelopeChat,
  check: Check,
  "profile-phone": ProfilePhone,
  "phone-small": PhoneSmall,
  "house-filled": HouseFilled,
  house: House,
  comment: Comment,
  globe: Globe,
  arrow: Arrow,
  "arrow-right": Arrow,
  stork: Stork,
  "radio-unchecked": RadioUnchecked,
  "radio-tick-checked": RadioTickChecked,
  "radio-tick-checked-filled": RadioTickCheckedFilled,
  "radio-dot-checked": RadioDotChecked,
  payment: Payment,
  cookies: Cookies,
  star: Star,
  navigation: Navigation,
  cross: Cross,
  "summarize-filled": SummarizeFilled,
  summarize: Summarize,
  "doula-filled": DoulaFilled,
  doula: Doula,
  "gift-filled": GiftFilled,
  gift: Gift,
  "info-rounded": InfoRounded,
  info: Info,
  block: Block,
  "assisted-pregnancy": AssistedPregnancy,
  "egg-freezing": EggFreezing,
  adoption: Adoption,
  surrogacy: Surrogacy,
  "settings-app-settings": SettingsAppSettings,
  "settings-basics": SettingsBasics,
  "settings-health-goal": SettingsHealthGoal,
  "settings-health-partner": SettingsHealthPartner,
  "settings-health-profile": SettingsHealthProfile,
  "settings-logout": SettingsLogout,
  package: IconPackage,
  chat: Chat,
  dots: Dots,
  fertility: Fertility,
  "self-care": SelfCare,
  "child-care": ChildCare,
  suitcase: Suitcase,
  rewind: Rewind,
  male: Male,
  syringe: Syringe,
  cabin: Cabin,
  lock: IconLock,
  "test-tube": TestTube,
  breastfeeding: Breastfeeding,
  "nursing-rooms": NursingRooms,
  "baby-bottle": BabyBottle,
  "backup-care": BackupCare,
  "play-pause": PlayPause,
  "gender-affirming": GenderAffirming,
  home: Home,
  people: People,
  insight: Insight,
  bell: Bell,
  redeem: IconRedeem,
  remove: IconRemove,
  plus: IconPlus,
  upload: Upload,
  dollar: Dollar,
  "ob-gyn": IconObGyn,
  "home-hearth": IconHomeHearth,
  "diabetes-management": DiabetesManagement,
  "heart-health": HeartHealth,
  "endometriosis-care": EndometriosisCare,
  "pelvic-health": PelvicHealth,
  "chronic-conditions": ChronicConditions,
  cancel: IconCancel,
  "triangle-pointer": TrianglePointer,
};
</script>
<script setup lang="ts">
import { computed } from "vue";

export type IconName = keyof typeof iconsDict;

const props = defineProps<{
  name: IconName;
}>();

const icon = computed(() => iconsDict[props.name]);
</script>

<style scoped lang="postcss">
.ui-icon {
  display: inline-block;
}

.inverse {
  transform: rotate(180deg);
}
</style>
